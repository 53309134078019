import React, { Component } from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import Layout from '../components/layout'
import ItemListing from '../components/item-listing'
import Card from '../components/card'
import styled from 'styled-components'
import HeroArea from '../components/hero-area'
import CalloutBar from '../components/callout-bar'
import EstimateForm from '../components/estimate-form'

const MainContentContainer = styled.div`
  h1 {
    margin-bottom: 0.25em;
  }
`

const SidebarContainer = styled.div``
const Spacer = styled.div`
  height: 5px;
`

const MapTitle = styled.p`
  text-align: center;
  font-size: 12px;
  padding-top: 10px;
  font-weight: 600;
`

class PageTemplate extends Component {
  render() {
    const pageData = this.props.data.contentfulPage

    const showContactForm = this.props.location.pathname.includes('contact')

    const SidebarColumn = () => {
      const sideBarCards = pageData.sidebar.map((sidebar, index) => (
        <Card key={index} sidebar={sidebar} />
      ))

      return sideBarCards
    }

    return (
      <Layout pageProps={this.props}>
        <SEO
          title={pageData.metaTitle}
          description={pageData.metaDescription}
          robots={pageData.metaRobotsContent}
        />

        {pageData.heroArea ? <HeroArea heroContent={pageData.heroArea} /> : ''}
        {pageData.calloutBar ? (
          <CalloutBar calloutContent={pageData.calloutBar} />
        ) : (
          ''
        )}

        {pageData && pageData.showReputationawardsSection ? (
          <section className="customfeaturedbar">
            <div className="container">
              <div className="row">
                <MainContentContainer
                  className={
                    pageData.sidebar
                      ? 'col-12 is-service-page'
                      : 'col-12 is-service-page'
                  }
                >
                  {pageData &&
                  pageData.featuredContent &&
                  pageData.featuredContent.featuredContent ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: pageData.featuredContent.featuredContent,
                      }}
                    />
                  ) : (
                    ''
                  )}
                </MainContentContainer>
              </div>
            </div>
          </section>
        ) : null}

        <div className="container brand-padding-y">
          <div className="row">
            <MainContentContainer
              className={
                pageData.sidebar
                  ? 'col-12 col-md-8 contained-page-x'
                  : 'col-12 contained-page-x'
              }
            >
              {/* <h1>{pageData.pageTitle}</h1> */}

              {pageData && pageData.body && pageData.body.body ? (
                <div dangerouslySetInnerHTML={{ __html: pageData.body.body }} />
              ) : (
                ''
              )}

              {showContactForm ? (
                <EstimateForm
                  showServicesList={true}
                  showLabels={true}
                  pageLocation={
                    this.props.location.href
                      ? this.props.location.href
                      : 'Not provided'
                  }
                />
              ) : (
                ''
              )}
              {showContactForm ? (
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <div className="mt-16 rounded overflow-hidden shadow">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d396545.6587649583!2d-94.78693059009572!3d39.060618840999815!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87c0fb2c419e1a61%3A0x3ac734e52af84d55!2sUS%20Quality%20Construction%20-%20Kansas%20City%20Siding%20Replacement%20Contractor!5e0!3m2!1sen!2sus!4v1620605873925!5m2!1sen!2sus"
                        height="250"
                        frameborder="0"
                        style={{
                          border: 0,
                          width: '100%',
                          margin: '0',
                          marginBottom: '-10px',
                        }}
                        aria-hidden="false"
                        tabindex="0"
                      ></iframe>
                    </div>
                    <MapTitle>Kansas City &amp; Surrounding</MapTitle>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <div className="mt-16 rounded overflow-hidden shadow">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9479.040881339672!2d-93.25733028956641!3d38.68796337296718!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7519cb24c92d719!2sUS%20Quality%20Construction%20-%20Siding%20Replacement%20%26%20Installation%20Contractor!5e0!3m2!1sen!2sus!4v1620605958868!5m2!1sen!2sus"
                        height="250"
                        frameborder="0"
                        style={{
                          border: 0,
                          width: '100%',
                          margin: '0',
                          marginBottom: '-10px',
                        }}
                        aria-hidden="false"
                        tabindex="0"
                      ></iframe>
                    </div>
                    <MapTitle>Mid-Missouri</MapTitle>
                  </div>
                </div>
              ) : null}
              {pageData.itemListing ? (
                <ItemListing itemList={pageData.itemListing} />
              ) : (
                ''
              )}
            </MainContentContainer>

            {pageData.sidebar ? (
              <SidebarContainer className="col-12 col-md-4">
                <SidebarColumn />
              </SidebarContainer>
            ) : (
              ''
            )}
          </div>
        </div>
      </Layout>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query pageQuery($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      id
      pageTitle
      metaTitle
      metaDescription
      slug
      itemListing {
        id
        serviceTitle
        excerpt
        slug
        heroImage {
          gatsbyImageData(width: 370)
        }
      }

      heroArea {
        id
        name
        heroTitle
        description {
          description
        }
        heroImage {
          gatsbyImageData(width: 1200, placeholder: BLURRED)
        }

        primaryActionBtnLabel
        primaryActionBtnLink
      }
      showReputationawardsSection
      featuredContent {
        featuredContent
      }
      body {
        body
      }
      calloutBar {
        id
        title
        description
        backgroundColor
        textColor
      }
      sidebar {
        id
        title
        content {
          id
          content
        }
        cssClasses
      }
    }
  }
`

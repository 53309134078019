import React from 'react'

import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import Button from './button'
import Slider from 'react-slick-preact-patch'
import { ChevronLeft, ChevronRight } from 'react-feather'

import HeroRatingStars from './hero-rating-stars'

const isBuildingHTML = typeof window === 'undefined'

const NextArrow = (props) => {
  const { onClick } = props

  //auto scroll
  //setInterval(() => onClick(), 10000)

  return (
    <div
      className="icon-container carousel-icon-left PrevNextArrowsContainer"
      onClick={onClick}
    >
      <ChevronLeft size={26} />
    </div>
  )
}
const PrevArrow = (props) => {
  const { onClick } = props
  return (
    <div
      className="icon-container carousel-icon-right PrevNextArrowsContainer"
      onClick={onClick}
    >
      <ChevronRight size={26} />
    </div>
  )
}

const HeroSection = ({
  heroImage,
  heroTitle,
  description,
  primaryActionBtnLabel,
  primaryActionBtnLink,
  standaloneImg,
  sliderVariant,
}) => (
  <section
    className={'hero hero-Stacked'}
    {...console.log('description', description.description)}
  >
    <GatsbyImage image={getImage(heroImage)} className="heroAreaImg" />
    <div className="hero-c-container container">
      <div className="row">
        <div
          className={`c-col col-12 col-md-6${
            sliderVariant ? ' sliderfeatwid' : ''
          }`}
        >
          <h3
            className={
              sliderVariant
                ? 'feature-extended-image has-animation'
                : 'before:background-secondary'
            }
          >
            {heroTitle}
          </h3>
          {sliderVariant ? (
            <>
              <div className="hero-description">
                {description.description ? (
                  <div
                    className="feature-extended-image has-animation pb-3"
                    dangerouslySetInnerHTML={{
                      __html: description.description,
                    }}
                  />
                ) : (
                  ''
                )}

                {primaryActionBtnLink ? (
                  <Button
                    to={primaryActionBtnLink}
                    color={'secondary'}
                    className={
                      'feature-extended-image has-animation noshadowtxt'
                    }
                  >
                    {primaryActionBtnLabel}
                  </Button>
                ) : null}
              </div>
              <HeroRatingStars />
            </>
          ) : (
            <>
              {description.description ? (
                <div
                  dangerouslySetInnerHTML={{ __html: description.description }}
                />
              ) : (
                ''
              )}
              {primaryActionBtnLink ? (
                <Button
                  className={'hero-area-primary-btn'}
                  to={primaryActionBtnLink}
                  color={'secondary'}
                >
                  {primaryActionBtnLabel}
                </Button>
              ) : null}
            </>
          )}
        </div>
        {standaloneImg && (
          <div className="standalone-img d-none d-md-block col-12 col-md-6">
            <GatsbyImage image={getImage(heroImage)} alt={heroTitle} />
          </div>
        )}
      </div>
    </div>
  </section>
)

const settings = {
  dots: false,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 8000,
  pauseOnHover: false,
  speed: 300,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  cssEase: 'linear',
  className: 'disable-interaction-on-non-active relative',
  nextArrow: <PrevArrow />,
  prevArrow: <NextArrow />,
}

const HeroArea = ({ heroContent }) => {
  let isMultiSlide = false

  if (heroContent.length || heroContent.length > 2) {
    isMultiSlide = true
  }

  if (isMultiSlide) {
    return (
      <div
        className={
          'slider-container' + (isBuildingHTML ? ' server-rendered' : '')
        }
      >
        <Slider {...settings}>
          {heroContent.map((item, index) => (
            <HeroSection {...item} key={index} sliderVariant />
          ))}
        </Slider>
      </div>
    )
  } else {
    return <HeroSection {...heroContent} standaloneImg />
  }
}

export default HeroArea
